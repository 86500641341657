import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const LinkListItem = styled.li`
    margin: 0;
    padding: 0;
`;

export const StyledLink = styled.a`
    display: block;
    width: 100%;
    margin: 0;
    color: ${theme.colors['text-global-secondary']};
    font-size: ${theme.typography.p3.fontSize};
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
`;
