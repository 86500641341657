import styled from '@emotion/styled';

export const PhotoBlock = styled.a`
    display: block;
    max-width: 150px;
`;

export const Img = styled.img`
    display: block;
    width: 100%;
    object-fit: contain;
`;
