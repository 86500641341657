import type { CookieLinkButton, LinkType } from '@config/widgets/pageFooter/type';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import dayjs from 'dayjs';
import type { JSX } from 'react';

import { Copyright, Link, LinkButton, List, ListItem, Wrapper } from './Consent.theme';

interface Props {
    consentLinks: (LinkType | CookieLinkButton)[];
}

const openOneTrustBanner = (): void => {
    if (typeof window !== 'undefined' && window.OneTrust) {
        window.OneTrust.ToggleInfoDisplay();
    }
};

export const isCookieButton = (consentLink: LinkType | CookieLinkButton): consentLink is CookieLinkButton => {
    return (consentLink as CookieLinkButton).button === 'cookieButton';
};

export const Consent = ({ consentLinks }: Props): JSX.Element => {
    const currentYear = dayjs().year();
    const [t] = useTranslations();
    const { trackEvent } = useTracking();

    return (
        <Wrapper>
            <Copyright>{t('frontend.footer.copyright', { year: String(currentYear) })}</Copyright>
            <List>
                {consentLinks.map((consentLink) => {
                    if (isCookieButton(consentLink)) {
                        return (
                            <ListItem key={consentLink.label}>
                                <LinkButton
                                    onClick={(): void => {
                                        openOneTrustBanner();
                                        const { eventName, touchPointButton } = consentLink.tracking;
                                        trackEvent(eventName, { touch_point_button: touchPointButton });
                                    }}
                                >
                                    {t(consentLink.label)}
                                </LinkButton>
                            </ListItem>
                        );
                    }

                    return (
                        <ListItem key={consentLink.label}>
                            <Link
                                href={consentLink.href}
                                onClick={(): void => {
                                    const { eventName, touchPointButton } = consentLink.tracking;
                                    trackEvent(eventName, { touch_point_button: touchPointButton });
                                }}
                            >
                                {t(consentLink.label)}
                            </Link>
                        </ListItem>
                    );
                })}
            </List>
        </Wrapper>
    );
};
