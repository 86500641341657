import { PAGE_FOOTER_CONFIG } from '@config/widgets/pageFooter/pageFooterWidgetConfig';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';
import { useTrackImpression } from '@domains/shared/hooks/useTrackImpression/useTrackImpression';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import { Col } from '@nexus/lib-react/dist/core/Layout';
import type { FC } from 'react';
import { memo, useContext, useRef } from 'react';

import { Consent } from './components/Consent/Consent';
import { DesktopLinksSection, MobileLinksSection } from './components/LinksSection/LinksSection';
import { SocialsAndMarkets } from './components/SocialsAndMarkets/SocialsAndMarkets';
import {
    BrandLogo,
    Footer,
    LinksSectionContainer,
    LinksSectionRow,
    LinksSectionWrapper,
    SectionWrapper,
} from './PageFooter.theme';

const { staticAssetsPrefix } = getPublicEnvConfig();

export const BasePageFooter: FC = () => {
    const { mobileAppsMarkets, socialLinks, linkLists, consentLinks, logo } = PAGE_FOOTER_CONFIG;
    const [t] = useTranslations();
    const { isDesktop, isTablet } = useContext(RWDContext);
    const { trackEvent } = useTracking();
    const footerRef = useRef<HTMLAnchorElement | null>(null);

    useTrackImpression(footerRef, () => {
        trackEvent('footer_impression', { touch_point_button: 'footer' });
    });

    return (
        <Footer ref={footerRef}>
            <LinksSectionWrapper>
                <LinksSectionContainer>
                    <LinksSectionRow>
                        <Col xs={12}>
                            <BrandLogo
                                src={`${staticAssetsPrefix}${logo.src}`}
                                alt={t('frontend.footer.brand-logo.alternative-text')}
                                loading="lazy"
                                height={logo.height}
                                width={logo.width}
                            />
                            {isDesktop === false && isTablet === false ? (
                                <MobileLinksSection linkLists={linkLists} />
                            ) : (
                                <DesktopLinksSection linkLists={linkLists} />
                            )}
                        </Col>
                    </LinksSectionRow>
                </LinksSectionContainer>
            </LinksSectionWrapper>
            <SectionWrapper>
                <SocialsAndMarkets mobileAppsMarkets={mobileAppsMarkets} socialLinks={socialLinks} />
            </SectionWrapper>
            <SectionWrapper>
                <Consent consentLinks={consentLinks} />
            </SectionWrapper>
        </Footer>
    );
};

export const PageFooter = memo(BasePageFooter);
