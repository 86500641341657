import type { LinkType } from '@config/widgets/pageFooter/type';
import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { JSX } from 'react';

import { LinkListItem, StyledLink } from './FooterLink.theme';

interface Props {
    link: LinkType;
}

export const FooterLink = ({ link }: Props): JSX.Element => {
    const { href, rel, target, label, tracking } = link;
    const [t] = useTranslations();
    const { trackEvent } = useTracking();
    const isLocalLink = href.includes('[lang]');

    const handleClick = (): void => {
        const { eventName, touchPointButton } = tracking;
        trackEvent(eventName, { touch_point_button: touchPointButton });
    };

    if (isLocalLink) {
        return (
            <LinkListItem>
                <LocalLink passHref href={href}>
                    <StyledLink rel={rel} target={target} onClick={handleClick}>
                        {t(label)}
                    </StyledLink>
                </LocalLink>
            </LinkListItem>
        );
    }

    return (
        <LinkListItem>
            <StyledLink href={href} rel={rel} target={target} onClick={handleClick}>
                {t(label)}
            </StyledLink>
        </LinkListItem>
    );
};
