import type { PageFooterWidgetConfig } from './type';

export const PAGE_FOOTER_CONFIG: PageFooterWidgetConfig = {
    linkLists: [
        {
            title: 'frontend.footer.links.title-about-us',
            links: [
                {
                    href: 'https://ajuda.imovirtual.com/imovirtualhelp/s/article/kA009000001vPCRCA2',
                    label: 'frontend.footer.links.about-us',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'about_us',
                    },
                },
                {
                    href: 'https://www.joinolx.com/',
                    label: 'frontend.footer.links.careers',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'career',
                    },
                },
                {
                    href: 'https://ajuda.imovirtual.com',
                    label: 'frontend.footer.links.help-center',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'help_center',
                    },
                },
                {
                    href: 'https://www.livroreclamacoes.pt/inicio',
                    label: 'frontend.footer.links.book-of-complaints',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'livroreclamacoes.pt/',
                    },
                },
                {
                    href: 'https://ajuda.imovirtual.com/imovirtualhelp/s/article/EU-Digital-Services-Act-DSA',
                    label: 'frontend.footer.links.digital-service',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'dsa',
                    },
                },
            ],
        },
        {
            title: 'frontend.footer.links.title-professionals',
            links: [
                {
                    href: '/[lang]/precos',
                    label: 'frontend.footer.links.prices',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'price_list',
                    },
                },
                {
                    href: 'https://profissionais.imovirtual.com/',
                    label: 'frontend.footer.links.advantages-for-professionals',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'for_professionals_academy',
                    },
                },
                {
                    href: 'https://developer.olxgroup.com/',
                    label: 'frontend.footer.links.crm-integrations',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'tools_for_offices',
                    },
                },
            ],
        },
        {
            title: 'frontend.footer.links.title-knowledge-hub',
            links: [
                {
                    href: '/noticias/',
                    label: 'frontend.footer.links.articles-and-tips',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'blog',
                    },
                },
            ],
        },
        {
            title: 'frontend.footer.links.title-olx-group',
            links: [
                {
                    href: 'https://www.olx.pt/',
                    label: 'frontend.footer.links.olx',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'olx.pt',
                    },
                },
                {
                    href: 'https://www.standvirtual.com/',
                    label: 'frontend.footer.links.standvirtual',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'https://www.standvirtual.com/',
                    },
                },
            ],
        },
        {
            title: 'frontend.footer.links.title-sitemap',
            links: [
                {
                    href: '/[lang]/mapa-de-categorias/',
                    label: 'frontend.footer.links.site-map',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'map_by_category',
                    },
                },
                {
                    href: '/[lang]/mapa-de-categorias/regioes/apartamento',
                    label: 'frontend.footer.links.cities-map',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'map_by_location',
                    },
                },
            ],
        },
        {
            title: 'frontend.footer.links.title-contact',
            links: [
                {
                    href: 'https://ajuda.imovirtual.com/imovirtualhelp/s/contactsupport',
                    label: 'frontend.footer.links.contact-client',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'contact_support',
                    },
                },
            ],
        },
    ],
    mobileAppsMarkets: {
        googlePlay: {
            logo: {
                src: '/images/pageFooter/mobileAppsMarkets/google_play.svg',
                width: 120,
                height: 40,
            },
            appUrl: 'https://play.google.com/store/apps/details?id=com.olx.realestate.app.imovirtual&referrer=utm_source%3Dimovirtual.com%26utm_medium%3Dcpc%26utm_campaign%3Dandroid-app-footer',
            tracking: {
                eventName: 'footer_link_click',
                touchPointButton: 'google_play',
            },
        },
        appStore: {
            logo: {
                src: '/images/pageFooter/mobileAppsMarkets/app_store.svg',
                width: 131,
                height: 40,
            },
            appUrl: 'https://apps.apple.com/pt/app/imovirtual-nova-app/id6476564616',
            tracking: {
                eventName: 'footer_link_click',
                touchPointButton: 'app_store',
            },
        },
    },
    socialLinks: [
        {
            icon: '/images/pageFooter/socialIcons/facebook.svg',
            href: 'https://facebook.com/Imovirtual',
            title: 'frontend.footer.links.social-facebook',
            target: '_blank',
            tracking: {
                eventName: 'footer_link_click',
                touchPointButton: 'facebook',
            },
        },
        {
            icon: '/images/pageFooter/socialIcons/instagram.svg',
            href: 'https://instagram.com/imovirtual/',
            title: 'frontend.footer.links.social-instagram',
            target: '_blank',
            tracking: {
                eventName: 'footer_link_click',
                touchPointButton: 'instagram',
            },
        },
        {
            icon: '/images/pageFooter/socialIcons/linkedin.svg',
            href: 'https://www.linkedin.com/company/imovirtual/',
            title: 'frontend.footer.links.social-linkedin',
            target: '_blank',
            tracking: {
                eventName: 'footer_link_click',
                touchPointButton: 'linkedin',
            },
        },

        {
            icon: '/images/pageFooter/socialIcons/youtube.svg',
            href: 'https://youtube.com/user/Imovirtual',
            title: 'frontend.footer.links.social-youtube',
            target: '_blank',
            tracking: {
                eventName: 'footer_link_click',
                touchPointButton: 'youtube',
            },
        },
    ],
    consentLinks: [
        {
            href: 'https://ajuda.imovirtual.com/imovirtualhelp/s/article/kA009000001vPCTCA2',
            label: 'frontend.footer.links.terms-of-use',
            tracking: {
                eventName: 'footer_link_click',
                touchPointButton: 'terms_of_cooperation',
            },
        },
        {
            href: 'https://ajuda.imovirtual.com/imovirtualhelp/s/article/kA009000001vPC3CAM',
            label: 'frontend.footer.links.privacy-policy',
            tracking: {
                eventName: 'footer_link_click',
                touchPointButton: 'privacy_policy',
            },
        },
        {
            button: 'cookieButton',
            label: 'frontend.footer.links.cookie-settings',
            tracking: {
                eventName: 'footer_link_click',
                touchPointButton: 'cookie_settings',
            },
        },
    ],
    logo: {
        src: '/images/logo/imovirtual2.svg',
        width: 154,
        height: 24,
    },
};
