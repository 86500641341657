import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Container, Row } from '@nexus/lib-react/dist/core/Layout';

export const Footer = styled.footer`
    padding: 0 ${theme.space[16]};

    @media ${theme.mediaQueries.mdMin} {
        padding: 0;
    }
`;

export const SectionWrapper = styled.div`
    display: flex;
    align-items: center;
    min-height: 88px;
    padding: ${theme.space[32]} 0;
    border-top: 1px solid ${theme.colors['borders-global-tertiary']};

    @media ${theme.mediaQueries.mdMin} {
        padding: ${theme.space[24]} 0;
    }
`;

export const LinksSectionWrapper = styled(SectionWrapper)`
    padding-bottom: 0;

    @media ${theme.mediaQueries.mdMin} {
        padding: ${theme.space[32]} 0 0;
    }
`;

export const BrandLogo = styled.img`
    height: 30px;
    object-fit: contain;
    margin: 0 0 ${theme.space[32]};

    @media ${theme.mediaQueries.mdMin} {
        margin: ${theme.space[32]} 0;
    }
`;

export const LinksSectionContainer = styled(Container)`
    display: block;
    padding: 0 ${theme.space[8]};

    @media ${theme.mediaQueries.xlMin} {
        padding: 0 ${theme.space[4]};
    }
`;

export const LinksSectionRow = styled(Row)`
    display: block;
`;
