import type { MobileAppsMarkets, SocialLinkType } from '@config/widgets/pageFooter/type';
import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { JSX } from 'react';

import { AppMarket, Box, Container, List, ListItem, ListTitle, SocialLink } from './SocialsAndMarkets.theme';

interface Props {
    mobileAppsMarkets: MobileAppsMarkets;
    socialLinks: SocialLinkType[];
}

export const SocialsAndMarkets = ({ mobileAppsMarkets, socialLinks }: Props): JSX.Element => {
    const { staticAssetsPrefix } = getPublicEnvConfig();
    const [t] = useTranslations();
    const { trackEvent } = useTracking();

    const { appStore, googlePlay } = mobileAppsMarkets;

    return (
        <Container>
            <Box>
                <ListTitle>{t('frontend.footer.find-us')}</ListTitle>
                <List>
                    {socialLinks.map(({ title, href, icon, target, tracking }) => {
                        return (
                            <ListItem key={title}>
                                <SocialLink
                                    href={href}
                                    icon={`${staticAssetsPrefix}${icon}`}
                                    title={t(title)}
                                    target={target}
                                    onClick={(): void => {
                                        const { eventName, touchPointButton } = tracking;
                                        trackEvent(eventName, { touch_point_button: touchPointButton });
                                    }}
                                />
                            </ListItem>
                        );
                    })}
                </List>
            </Box>
            <Box>
                <ListTitle>{t('frontend.footer.mobile-apps')}</ListTitle>
                <List>
                    <ListItem>
                        <AppMarket
                            href={appStore.appUrl}
                            src={`${staticAssetsPrefix}${appStore.logo.src}`}
                            alt={t('frontend.footer.links.apps.app-store-aria')}
                            isLazyLoaded={true}
                            onClick={(): void => {
                                const { eventName, touchPointButton } = appStore.tracking;
                                trackEvent(eventName, { touch_point_button: touchPointButton });
                            }}
                            width={appStore.logo.width}
                            height={appStore.logo.height}
                        />
                    </ListItem>
                    <ListItem>
                        <AppMarket
                            href={googlePlay.appUrl}
                            src={`${staticAssetsPrefix}${googlePlay.logo.src}`}
                            alt={t('frontend.footer.links.apps.google-play-aria')}
                            isLazyLoaded={true}
                            onClick={(): void => {
                                const { eventName, touchPointButton } = googlePlay.tracking;
                                trackEvent(eventName, { touch_point_button: touchPointButton });
                            }}
                            width={googlePlay.logo.width}
                            height={googlePlay.logo.height}
                        />
                    </ListItem>
                </List>
            </Box>
        </Container>
    );
};
