import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: auto;
    height: 100%;
    margin: 0 auto;

    @media ${theme.mediaQueries.lgMin} {
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
`;

export const Copyright = styled.p`
    width: 100%;
    margin: 0 0 ${theme.space[16]};
    color: ${theme.colors['text-global-secondary']};
    font-size: ${theme.typography.p4.fontSize};
    text-align: center;
    white-space: nowrap;

    @media ${theme.mediaQueries.lgMin} {
        width: auto;
        margin: 0 ${theme.space[24]} 0 0;
    }
`;

export const List = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 360px;
    margin: 0 auto;
    padding: 0;
    list-style: none;

    @media ${theme.mediaQueries.mdMin} {
        flex-flow: row nowrap;
        max-width: none;
        margin: 0;
    }
`;

export const ListItem = styled.li`
    @media ${theme.mediaQueries.mdMin} {
        &:not(:last-of-type) {
            border-right: 1px solid ${theme.colors['borders-global-tertiary']};
        }
    }
`;

const linkStyles = (): SerializedStyles => css`
    padding: 0 ${theme.space[4]};
    color: ${theme.colors['text-global-primary']};
    font-size: ${theme.typography.p4.fontSize};
    font-weight: ${theme.fontWeights.bold};
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;

    &:hover,
    &:focus {
        text-decoration: underline;
    }

    @media ${theme.mediaQueries.mdMin} {
        padding: 0 ${theme.space[8]};
        cursor: pointer;
    }
`;

export const Link = styled.a`
    ${linkStyles()};
`;

export const LinkButton = styled.button`
    ${linkStyles()};

    border: none;
    background: none;
    box-shadow: none;
`;
