import { LinkPhoto } from '@domains/shared/components/LinkPhoto';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: auto;
    margin: 0 auto;

    @media ${theme.mediaQueries.lgMin} {
        flex-direction: row;
        justify-content: center;
    }
`;

export const Box = styled.div`
    @media ${theme.mediaQueries.lgMin} {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 ${theme.space[16]};

        &:first-of-type {
            border-right: 1px solid ${theme.colors['borders-global-tertiary']};
        }
    }

    @media ${theme.mediaQueries.lgMin} {
        padding: 0 ${theme.space[40]};
    }
`;

export const ListTitle = styled.p`
    margin: 0 auto ${theme.space[16]};
    color: ${theme.colors['text-global-primary']};
    font-size: ${theme.typography.p4.fontSize};
    font-weight: ${theme.fontWeights.bold};
    text-align: center;
    text-transform: uppercase;

    @media ${theme.mediaQueries.lgMin} {
        margin: 0 ${theme.space[16]} 0 0;
    }
`;

export const List = styled.ul`
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
`;

export const ListItem = styled.li`
    margin: 0;
    padding: 0;
`;

export const AppMarket = styled(LinkPhoto)`
    width: auto;
    height: 40px;
    margin: 0 ${theme.space[4]};

    > img {
        object-fit: contain;
        width: auto;
        height: 100%;
    }

    @media ${theme.mediaQueries.lgMin} {
        margin: 0 ${theme.space[8]};
    }
`;

interface SocialPlatformProps {
    icon: string;
}

export const SocialLink = styled.a`
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 ${theme.space[8]} ${theme.space[24]};
    transition: background-color 0.2s ease-in-out;
    background-color: ${theme.colors['text-global-primary']};
    mask: url(${(props: SocialPlatformProps): string => props.icon}) no-repeat center center;

    &:hover,
    &:focus {
        background-color: ${theme.colors['icon-global-highlight']};
    }

    @media ${theme.mediaQueries.lgMin} {
        margin-bottom: 0;
    }
`;
