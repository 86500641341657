import type { LinkListType, LinkType } from '@config/widgets/pageFooter/type';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { AccordionContent } from '@nexus/lib-react/dist/core/Accordion';
import type { JSX } from 'react';

import { FooterLink } from '../FooterLink/FooterLink';
import {
    AccordionWrapper,
    LinkList,
    List,
    ListItem,
    ListTitle,
    StyledAccordion,
    StyledAccordionHeader,
} from './LinksSection.theme';

interface Props {
    linkLists: LinkListType[];
}

const createAccordionHeaderComponent = (title: string): (() => JSX.Element) => {
    const AccordionHeaderComponent = (): JSX.Element => {
        const [t] = useTranslations();

        return <StyledAccordionHeader>{t(title)}</StyledAccordionHeader>;
    };

    return AccordionHeaderComponent;
};

const createAccordionContentComponent = (links: LinkType[]): (() => JSX.Element) => {
    const AccordionContentComponent = (): JSX.Element => (
        <AccordionContent>
            <LinkList>
                {links.map((link) => (
                    <FooterLink link={link} key={link.label} />
                ))}
            </LinkList>
        </AccordionContent>
    );

    return AccordionContentComponent;
};

export const MobileLinksSection = ({ linkLists }: Props): JSX.Element => {
    const accordionContent = linkLists.map((linkList) => {
        return {
            id: linkList.title,
            header: createAccordionHeaderComponent(linkList.title),
            content: createAccordionContentComponent(linkList.links),
        };
    });

    return (
        <AccordionWrapper>
            <StyledAccordion content={accordionContent} data-testid="footer-mobile-links" />
        </AccordionWrapper>
    );
};

export const DesktopLinksSection = ({ linkLists }: Props): JSX.Element => {
    const [t] = useTranslations();

    return (
        <List data-testid="footer-desktop-links">
            {linkLists.map((linkList) => {
                return (
                    <ListItem key={linkList.title}>
                        <ListTitle>{t(linkList.title)}</ListTitle>
                        <LinkList>
                            {linkList.links.map((link) => (
                                <FooterLink link={link} key={link.label} />
                            ))}
                        </LinkList>
                    </ListItem>
                );
            })}
        </List>
    );
};
