import type { ComponentProps, JSX } from 'react';

import { Img, PhotoBlock } from './LinkPhoto.theme';

interface Props extends Pick<ComponentProps<'a'>, 'onClick' | 'target' | 'className' | 'title' | 'rel'> {
    alt?: string;
    href: string;
    src: string;
    width?: number | 'auto';
    height?: number | 'auto';
    isLazyLoaded?: boolean;
    dataCy?: string;
}

export const LinkPhoto = ({
    className,
    alt = '',
    href,
    src,
    title,
    width,
    height,
    isLazyLoaded = false,
    target,
    rel,
    onClick,
    dataCy,
}: Props): JSX.Element => (
    <PhotoBlock
        className={className}
        href={href}
        title={title}
        target={target}
        rel={rel}
        onClick={onClick}
        data-cy={dataCy}
    >
        <Img src={src} alt={alt} width={width} height={height} loading={isLazyLoaded ? 'lazy' : 'eager'} />
    </PhotoBlock>
);
